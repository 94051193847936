.title-name{
    font-size: 20px;
    color: rgb(26, 210, 149);
}
h1,h2,h3,h4 {
    color: rgb(27, 164, 164);
}
p.des-para,li {
    text-align: start;
    list-style-type: none;
    color: aliceblue;
}