.menu-items {
    color: white !important;
    text-transform: uppercase;
    font-weight: 600;
}
.site-title {
    font-size: 33px;
    margin-left: 20px;
    margin-right: 20px;
    color: #fdfdfd;
    font-family: fantasy;
    font-weight: 500;
}
.site-title-mobile {
    font-size: 24px;
    margin-left: 10px;
    margin-right: 10px;
    color: #fdfdfd;
    font-weight: 500;
}
.logo-img {
    width: 40px;
    height: 35px;
}
.ant-drawer-header {
    background: #000000;
}
.ant-drawer-title {
    color: aliceblue;
}
.ant-menu {
    background: #000000;
}
.li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    background: #000000;
}
.header-body {
    display: flex;
    align-items: center;
    gap: 0px;
    justify-content: flex-start;
    background: '#000000';
}
.traingle {
    width: 55px;
    height: 63px;
    position: relative;
    background: linear-gradient(120deg, #000000 50%, #123e39 48%);
}
@media (max-width: 600px) {
    .site-title {
        font-size: 24px;
        margin-right: 4px;
    }
    .logo-img {
        width: 25px;
        height: 20px;
    }
    .header-body {
        justify-content: space-between;
        gap: 30px;
    }
   .traingle {
    display: none;
   } 
}