.heading {
    padding-left: 10px;
    -webkit-background-clip: text;
    background-image: linear-gradient(45deg, #81fff5, #23349f);
    color: transparent;
    font-size: 33px;
    font-weight: 600;
    text-align: start;
}
.home-details {
    padding: 10px;
    justify-content: start;
}
.heading-list {
    padding: 5px;
    list-style-type: none;
}
.card-description {
    font-size: 20px;
    color: #cec6c6;
    text-align: start;
}
.site-title{
    font-size: 33px;
    padding: 0px;
    margin: 7px;
    color: #fdfdfd;
    font-family: fantasy;
    font-weight: 500;
}
.site-title:hover{
    color: #c2dbdf;
    cursor: pointer;
}
.feed {
    color: #34cfab;
}
.collection {
    -webkit-background-clip: text;
    background-image: linear-gradient(45deg, #826767, #ee6a2b);
    color: transparent;
    font-size: 24px;
    font-weight: 500;
    text-align: start;
}
ul.heading-list li {
    list-style-type: none;
    text-align: start;
}

ul.heading-list li p {
    text-align: start;
}